import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { El } from './../../components/layout'
import NoSSR from '../../components/NoSSR'
import { ReactComponent as PageNotFoundImage } from './assets/page-not-found.svg'
import { ReactComponent as PageNotFoundImageMobile } from './assets/page-not-found-mobile.svg'
import { useTranslation } from 'react-i18next'
import {
  PrimaryButton,
  PageContainer,
  PageTitle,
  PageText,
  ButtonGroups,
  LeftContainer,
  MobileImage,
  DesktopImage,
  FlexStyled
} from './PageNotFound.styles'

function PageNotFound() {
  const { t: TRANSLATOR } = useTranslation()
  return (
    <>
      <Helmet>
        <title>Page not found | Carla Car Rental</title>
        <meta name='description' content='Page not found' />
      </Helmet>
      <El bg='newColorPrimary'>
        <PageContainer>
          <FlexStyled>
            <LeftContainer>
              <NoSSR>
                <MobileImage>
                  <PageNotFoundImageMobile />
                </MobileImage>
              </NoSSR>
              <PageTitle>{TRANSLATOR('404:header')}</PageTitle>
              <PageText>
                {TRANSLATOR('404:text')} {TRANSLATOR('404:text2')}
              </PageText>
              <ButtonGroups>
                <PrimaryButton as={Link} to='/'>
                  {TRANSLATOR('404:back')}
                </PrimaryButton>
              </ButtonGroups>
            </LeftContainer>
            <NoSSR>
              <DesktopImage>
                <PageNotFoundImage />
              </DesktopImage>
            </NoSSR>
          </FlexStyled>
        </PageContainer>
      </El>
    </>
  )
}

export default PageNotFound
