import { useQuery, useQueryClient } from 'react-query'
import API from '../../services/API'
import util from '../../util'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setSearchPickupLocation, setSameLocation } from '../../redux/slices'

// call getPlace first to get coordinates for downtown search
// call getPlace after nearby search to get place details for nearby search
// uses same component for nearby and downtown search, decides which to use based on nearMePicked
// queries are removed from cache when switching between downtown and nearby search

export default function useAreaSearchQuery() {
  const dispatch = useDispatch()
  const { pickupLocation, pickupDateStr, dropOffDateStr, age, promoCode } = useSelector((state) => state.searchArgs)
  const { nearMePicked, nearMeCoords } = useSelector((state) => state.nearMePicked)
  const queryClient = useQueryClient()

  const pickupDate = util.formatDateForAPI(new Date(pickupDateStr))
  const dropOffDate = util.formatDateForAPI(new Date(dropOffDateStr))

  const { data: place, isLoading: isPlaceLoading } = useQuery(
    ['place', pickupLocation.value],
    () => API.getPlace(pickupLocation.value),
    {
      enabled: !nearMePicked,
      cacheTime: 1000 * 60 * 5,
      staleTime: 1000 * 60 * 5
    }
  )

  const { data: downtownCars, isLoading: isDowntownCarsLoading } = useQuery(
    ['downtownCars', place, pickupDateStr, dropOffDateStr],
    () =>
      API.searchDowntownSuppliers(pickupDate, dropOffDate, pickupLocation.value, pickupLocation.value, age, promoCode),
    {
      enabled: !!place && !nearMePicked,
      cacheTime: 1000 * 60 * 5,
      staleTime: 1000 * 60 * 5
    }
  )

  const { data: nearbyCars, isLoading: isNearbyCarsLoading } = useQuery(
    ['nearbyCars', nearMeCoords, pickupDateStr, dropOffDateStr],
    () => API.nearbyCarSearch(nearMeCoords.lat, nearMeCoords.lng, pickupDate, dropOffDate, age, promoCode),
    {
      enabled: nearMePicked,
      cacheTime: 1000 * 60 * 5,
      staleTime: 1000 * 60 * 5
    }
  )

  const { data: nearbyPlace, isLoading: isNearbyPlaceLoading } = useQuery(
    ['nearByPlace', nearbyCars],
    () => API.getPlace(nearbyCars.searchArgs.pickupLocation),
    {
      enabled: !!nearbyCars,
      cacheTime: 1000 * 60 * 5,
      staleTime: 1000 * 60 * 5,
      // To replace near me with the actual city after turning back from search
      onSuccess: (data) => {
        dispatch(setSearchPickupLocation({ value: data.id, label: data.prettyName, type: data.type }))
        dispatch(setSameLocation(true))
      }
    }
  )

  // Remove queries from cache when switching between downtown and nearby search
  useEffect(() => {
    if (!nearMePicked) {
      queryClient.removeQueries(['nearbyCars'])
      queryClient.removeQueries(['nearByPlace'])
    } else {
      queryClient.removeQueries(['downtownCars'])
      queryClient.removeQueries(['place'])
    }
  }, [nearMePicked, queryClient])

  return {
    downtownCars: downtownCars || nearbyCars, // TODO: Naming is terrible I know, gonna refactor both to a genereic name
    loading: isDowntownCarsLoading || isNearbyCarsLoading || isNearbyPlaceLoading || isPlaceLoading,
    pickupLocation: place || nearbyPlace,
    pickupDate,
    dropOffDate,
    age,
    nearMePicked,
    nearMeCoords
  }
}
