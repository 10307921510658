import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { ButtonEl, El, FlexEl, OrangeButton } from './layout'
import { IconClose } from './svg'
import styled from 'styled-components'
import { colors } from '../styles'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { themeGet } from 'styled-system'
import util from '../util'
import Loading from './Loading'
import { Translation } from 'react-i18next'

// full screen container
const ModalContainer = styled(FlexEl)`
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2000;
  width: 100%;
  background-color: rgb(0, 0, 0, 0.5);

  &.fade {
    background-color: rgb(0, 0, 0, 0);
    transition: all 200ms ease;
    // modal content
    > * {
      opacity: 0;
      transform: scale(0.5);
      transition: all 200ms ease;
    }
  }
  &.fade-enter {
    background-color: rgb(0, 0, 0, 0);
    > * {
      opacity: 0;
      transform: scale(0.5);
    }
  }
  &.fade-enter-active,
  &.fade-enter-done,
  &.fade-exit {
    @media (min-width: ${themeGet('breakpoints.1')}px) {
      background-color: rgb(0, 0, 0, 0.5);
    }
    > * {
      opacity: 1;
      transform: scale(1);
    }
  }
  &.fade-exit-active {
    background-color: rgb(0, 0, 0, 0);
    > * {
      opacity: 0;
      transform: scale(1.5);
    }
  }
`

// content (full screen)
const ModalContent = styled(FlexEl)`
  position: absolute;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2000;
  width: 100%;
  min-height: 100px;
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: 0;
  background-color: white;
  border-radius: 12px;
  background: #fff;
  > * {
    max-width: 100%;
  }
  // desktop
  @media (min-width: ${themeGet('breakpoints.1')}px) {
    width: ${(props) => props.width || 500}px;
    max-width: ${(props) => props.width || 500}px;
    max-height: 85%;
    margin-left: -${(props) => (props.width ? props.width / 2 : 250)}px;
    left: 50%;
    top: unset;
    bottom: unset;
    /* box-shadow: 0 0 30px rgb(0, 0, 0, 0.5); */
  }
`

// close icon
export const CloseButton = styled(ButtonEl)`
  background-color: transparent;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2001;
  width: 50px;
  height: 60px;
`

const OKButton = styled(OrangeButton)`
  width: 100%;
  margin-left: auto;
  font-size: 16px;
  padding: 15px;
  @media (min-width: ${themeGet('breakpoints.0')}px) {
    width: auto;
    padding: 10px 40px;
  }
`

const ModalHeader = styled(FlexEl)`
  width: 100%;
  height: 60px;
  align-items: center;
  padding: 15px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  border-bottom: 1px solid ${colors.silver};
`

// standard modal
class Modal extends React.Component {
  static propTypes = {
    title: PropTypes.string, // shows header component if true
    closable: PropTypes.bool, // shows cross icon if true
    ok: PropTypes.bool, // shows OK button if true
    isOpen: PropTypes.bool,
    onClose: PropTypes.func, // parent handler
    width: PropTypes.number // optional desktop width in pixel
  }

  static defaultProps = {
    isOpen: true,
    width: 500
  }

  state = {}

  closeClicked = (e) => {
    e.preventDefault()
    this.props.onClose(e)
    document.body.classList.remove('overflow-hidden')
  }

  outsideClicked = (e) => {
    if (!this.contentNode.contains(e.target) && this.props.onClose) {
      e.preventDefault()
      this.props.onClose(e)
      document.body.classList.remove('overflow-hidden')
    }
  }

  componentDidMount() {
    if (this.props.isOpen && util.isBrowser()) {
      document.body.classList.add('overflow-hidden')
    }
  }

  componentDidUpdate() {
    if (this.props.isOpen && util.isBrowser()) {
      document.body.classList.add('overflow-hidden')
    }
  }

  componentWillUnmount() {
    if (util.isBrowser()) {
      document.body.classList.remove('overflow-hidden')
    }
  }

  render() {
    const { children, title, closable, isOpen, width, ok, ...props } = this.props
    if (!isOpen) {
      return <Fragment />
    }

    return (
      <ModalContainer {...props} onClick={this.outsideClicked}>
        <ModalContent ref={(ref) => (this.contentNode = ref)} width={width} id='testModalContent'>
          {closable && (
            <CloseButton onClick={this.closeClicked}>
              <IconClose />
            </CloseButton>
          )}
          {title && <ModalHeader>{title}</ModalHeader>}
          {children}
          {ok && (
            <El width={1} p={15}>
              <OKButton onClick={this.closeClicked}>OK</OKButton>
            </El>
          )}
        </ModalContent>
      </ModalContainer>
    )
  }
}

// modal with transition
export class FadeModal extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool
  }

  render() {
    const { isOpen, ...props } = this.props
    return (
      <TransitionGroup>
        {isOpen && (
          <CSSTransition timeout={200} classNames='fade'>
            <Modal isOpen={true} className='fade' {...props} />
          </CSSTransition>
        )}
      </TransitionGroup>
    )
  }
}

const LoadingBox = styled(({ text, ...props }) => (
  <FlexEl {...props}>
    <El mt={40} fontSize={28}>
      {text}
    </El>
    <Loading width={100} my={100} />
  </FlexEl>
))`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 25px;
  text-align: center;
`

export const LoadingModal = ({ text = '', ...props }) => (
  <Modal {...props}>
    <LoadingBox text={text} />
  </Modal>
)
export const FadeLoadingModal = ({ text = '', ...props }) => (
  <FadeModal {...props}>
    <LoadingBox text={text} />
  </FadeModal>
)

const defaultTitle = 'Something went wrong!'
const defaultDescription = 'We are trying to fix it.'
export const ErrorBox = styled(({ title = defaultTitle, description = defaultDescription, ...props }) => (
  <FlexEl {...props}>
    <El as='h1' className='title'>
      {title}
    </El>
    {props.children}
    <El className='description'>{description}</El>
  </FlexEl>
))`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 25px;
  text-align: center;
  .title {
    color: ${themeGet('colors.colorPrimary')};
    font-size: 30px;
    margin-top: 28px;
  }
  .description {
    margin-top: 44px;
    margin-bottom: 0;
    font-size: 20px;
    color: #263238;
    font-weight: 600;
  }
  img {
    max-width: 100%;
  }
`

// TODO: move out
// this pops up when an exception is not handled by
export const DefaultErrorModal = ({ ...props }) => (
  <Modal {...props} ok={<Translation>{(t) => t('500:ctaButtonText')}</Translation>}>
    <ErrorBox
      title={<Translation>{(t) => t('500:title')}</Translation>}
      description={<Translation>{(t) => t('500:message')}</Translation>}
    >
      <img src='/images/error-ripped.png' alt='Error' />
    </ErrorBox>
  </Modal>
)

export default Modal
